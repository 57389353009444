import './Register.css';
import {Form, Grid} from "semantic-ui-react";
import {useRef, useState} from "react";
import CustomFileInput from "../../shared/CustomFileInput/CustomFileInput";
import CustomCheckbox from "../../shared/CustomCheckbox/CustomCheckbox";
import {useWindowSize} from "../../utils/useWindowSize";
import {api} from "../../utils/api";
import PersonalDataModal from "../../shared/PersonalDataModal/PersonalDataModal";
import {toast} from "react-toastify";
import InputMask from "react-input-mask";
import CustomDropdown from "../ServiceRequest/components/CustomDropdown/CustomDropdown";

export default function Register() {
  let [agreePersonalData, setAgreePersonalData] = useState(false);
  let [openPersonalDataModal, setOpenPersonalDataModal] = useState(false);

  let [vin, setVin] = useState('');
  let [vinError, setVinError] = useState(false);

  let [firstName, setFirstName] = useState('');
  let [firstNameError, setFirstNameError] = useState(false);
  let [lastname, setLastName] = useState('');
  let [lastnameError, setLastNameError] = useState(false);
  let [meddleName, setMeddleName] = useState('');
  let [meddleNameError, setMeddleNameError] = useState(false);

  let [phone, setPhone] = useState('');
  let [phoneError, setPhoneError] = useState(false);
  let [email, setEmail] = useState('');
  let [emailError, setEmailError] = useState(false);

  let [additionInfo, setAdditionInfo] = useState('');
  let [additionInfoError, setAdditionInfoError] = useState(false);

  let file1 = useRef(null);
  let [file1Error, setFile1Error] = useState(false);

  let file2 = useRef(null);
  let [file2Error, setFile2Error] = useState(false);

  let [isSend, setIsSend] = useState(false);
  let [sendText, setSendText] = useState(null);

  let [isLoading, setIsLoading] = useState(false);

  let [registerForCompany, setRegisterForCompany] = useState(false);
  let [companyOwnerName, setCompanyOwnerName] = useState('');
  let [companyOwnerNameError, setCompanyOwnerNameError] = useState(false);


  let size  = useWindowSize();

  let register = () => {
    if(vin === '')
      setVinError(true);
    if(firstName === '')
      setFirstNameError(true);
    if(lastname === '')
      setLastNameError(true);
    if(meddleName === '')
      setMeddleNameError(true);
    if(phone === '' || phone.indexOf('_') !== -1)
      setPhoneError(true);
    if(email === '')
      setEmailError(true);
    if(additionInfo === '')
      setAdditionInfoError(true);

    if(file1.current.files.length === 0)
      setFile1Error(true);

    if(file2.current.files.length === 0)
      setFile2Error(true);

    if(vin === '' || firstName === '' || lastname === '' || meddleName === ''
        || phone === '' || phone.indexOf('_') !== -1 || email === '' || additionInfo === '' ||
        file1.current.files.length === 0 || file2.current.files.length === 0)
      return;
    if(!agreePersonalData){
      setOpenPersonalDataModal(true);
      return;
    }
    if(isLoading)
      return;
    setIsLoading(true);
    api()
        .register(vin, registerForCompany ? 2 : 1, firstName, lastname, meddleName, phone, email, additionInfo, file1, file2)
        .then(resp => {
          setIsLoading(false);
          if(resp.ErrorInfo === null || resp.ErrorInfo === ''){
            setIsSend(true);
            setSendText(resp.UserInfo);
          }
          else{
            toast.error(resp.ErrorInfo);
          }
        });
  };

  return <div className={'register-page'} style={{backgroundImage: 'url("/images/' + window.config.restoreImgName + '.png")'}}>
    <div className={'right-panel white-paper'}></div>
    <div className={'right-panel blur'}></div>

    <div className={'right-panel register-panel'} style={{height: size.height + 'px', overflowY: 'scroll'}}>
      <div className={'go-back-message'}>У вас уже есть аккаунт? <a href={'/'}>Войти</a></div>
      {isSend
          ? (
              <div style={{paddingTop: '25px'}}>
                <h2>{sendText !== null ? sendText : 'Заявка на регистрацию создана'}</h2>
              </div>
          ) : (
              <div>
                <h1>Регистрация</h1>
                <Form autocomplete="nope">
                  <Form.Field>
                    <label>Тип контрагента</label>
                      <CustomDropdown
                          value={registerForCompany ? 2 : 1}
                          options={[
                            {id: 1, name: 'Физическое лицо'},
                            {id: 2, name: 'Юридическое лицо'},
                          ]}
                          onChange={(value) => setRegisterForCompany(value == 2)}
                          search
                          selection
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Автомобиль</label>
                      <input placeholder={'VIN'} value={vin}
                             onChange={e=>setVin(e.target.value)} className={(vinError ? 'error' : '')}
                             onFocus={()=>{setVinError(false)}}
                             autocomplete="nope"
                      />
                    </Form.Field>
          {registerForCompany ? <>
            <Form.Field>
              <label>Владелец</label>
              <input placeholder={'Наименование'} value={companyOwnerName}
                     onChange={e=>setCompanyOwnerName(e.target.value)} className={(companyOwnerNameError ? 'error' : '')}
                     onFocus={()=>{setCompanyOwnerNameError(false)}}
                     autocomplete="nope"
              />
            </Form.Field>
          </> : <>
            <Form.Field>
              <label>Владелец</label>
              <input placeholder={'Фамилия'} value={lastname}
                     onChange={e=>setLastName(e.target.value)} className={(lastnameError ? 'error' : '')}
                     onFocus={()=>{setLastNameError(false)}}
                     autocomplete="nope"
              />
            </Form.Field>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field>
                    <input placeholder={'Имя'} value={firstName}
                           onChange={e=>setFirstName(e.target.value)} className={(firstNameError ? 'error' : '')}
                           onFocus={()=>{setFirstNameError(false)}}
                           autocomplete="nope"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>

                  <Form.Field>
                    <input placeholder={'Отчество'} value={meddleName}
                           onChange={e=>setMeddleName(e.target.value)} className={(meddleNameError ? 'error' : '')}
                           onFocus={()=>{setMeddleNameError(false)}}
                           autocomplete="nope"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>}
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Form.Field>
                <label>Контакты</label>
                <InputMask
                    className={'custom-phone-input' + (phoneError ? ' error' : '')}
                    mask="+7 (999) 999-99-99"
                    placeholder={'Телефон'}
                    pattern="/^+7\d*"
                    value={phone}
                    onChange={e=>setPhone(e.target.value)}
                    onFocus={()=>{setPhoneError(false)}}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Field>
                <label>&nbsp;</label>
                <input placeholder={'E-mail'} value={email}
                       onChange={e=>setEmail(e.target.value)} className={(emailError ? 'error' : '')}
                       onFocus={()=>{setEmailError(false)}}
                       autocomplete="nope"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Form.Field style={{marginTop: '15px'}} autocomplete="nope">
          <label>Дополнительно</label>
          <input
              placeholder={'Любая дополнительная информация'}
              value={additionInfo} onChange={e=>setAdditionInfo(e.target.value)}
              className={(additionInfoError ? 'error' : '')}
              onFocus={()=>{setAdditionInfoError(false)}}
              autocomplete="nope"
          />
        </Form.Field>

        <Form.Field>
          <label>Лицевая и обратная стороны СТС (Свидетельства о регистрации ТС)</label>
        </Form.Field>
        <div className={'file-inputs-container'}>
          <div className={'file-item-container'}><CustomFileInput refToElement={file1} error={file1Error} onSetFile={()=>{setFile1Error(false)}}/></div>
          <div className={'file-item-container'}><CustomFileInput refToElement={file2} error={file2Error} onSetFile={()=>{setFile2Error(false)}}/></div>
        </div>

        <div style={{marginTop: '40px'}}><CustomCheckbox
            value={agreePersonalData}
            onChange={setAgreePersonalData}
            label={<>Я даю согласие на обработку <a onClick={(e) => {e.stopPropagation();
              setOpenPersonalDataModal(true);
            } }><b>персональных данных</b></a></>}/></div>
        <button className={'submit'} onClick={register} disabled={isLoading}>{isLoading ? <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> : 'Зарегистрироваться'}</button>
      </Form></div>)}
    </div>
    <PersonalDataModal
      openPersonalDataModal={openPersonalDataModal}
      setOpenPersonalDataModal={setOpenPersonalDataModal}
      setAgreePersonalData={setAgreePersonalData}
    />
  </div>;
}
