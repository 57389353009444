import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {api} from "./utils/api";

api()
    .getConfig(window.location.hostname)
    .then(config => {
        window.config = config;
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = '/images/' + window.config.faviconImgName + '.ico';
        document.title = window.config.name;
        document.querySelector('meta[name="description"]').setAttribute("content", window.config.name);
        var css = '.login-btn, .menu-items .menu-item.active, .to-list-container .create-to-request-btn, .menu-container .unread-marker, .quality-assessment-container .finish-btn, .login-btn, .messages-page .header .column:last-child button, .restore-panel .submit, .service-request-form .send-service-request, .settings-page .save-btn, .personal-data-modal .column:last-child button, .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before, .ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before, .register-panel .submit { background-color: '+window.config.color+'; }' +
                ' .all-news-page .news-item .link-container a, .my-dealer-page .marketing-items .marketing-item .item-title, .cars-page .car-item .details-container .car-name.mark, .cars-page .car-item .details-container .view-car-link, .car-selected-container .car-selected-info .details-container .car-name, .delete-car-modal .title, .year-selector .years-container .year-item.selected, .history-page .history-items .history-item .quality-assessment.not-complete, .history-page .history-items .history-item:hover .more-link, .login-page a, .login-page .error-message, .new-chat-modal .grid .row .column:first-child, .news-page .news-item .link-container a, .view-news-item-link, .view-news-item-link:hover, .news-item-page .news-item .link-container a, .news-page .news-item .link-container a, .register-page a, .restore-page a, .service-request-form .slot-selector .dates .date.active, .service-request-form .slot-selector .slots .slot.active, .success-create-serviceRequest-modal p.important, .service-request-page .description-for-service-request-item, .confirm-remove-serviceRequest-modal .title, .loader {color: '+window.config.color+';}'+
                ' .cars-page .car-item .details-container:hover, .history-page .history-items .history-item:hover { border: 2px solid '+window.config.color+';}'+
                ' .my-dealer-page .header .column:last-child button, .download-book-btn, .news-page .header .column:last-child button.edit-btn, .news-item-page .header .column:last-child button.edit-btn, .news-page .header .column:last-child button.edit-btn, .restore-panel .go-back-message a, .custom-input.error, .register-panel .go-back-message a, input.add_new_contact__phone-input.error {border: 1px solid '+window.config.color+';color: '+window.config.color+';}'+
                ' .restore-page input.error, .custom-file-input-container.error .label-container .label-name {border-color: '+window.config.color+' !important;}'+
                ' .loader:after {background: '+window.config.color+';}',
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

        head.appendChild(style);

        style.type = 'text/css';
        if (style.styleSheet){
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        if(window.config.theme === 'Dark'){
            let styleForDarkTheme = document.createElement('style');
            let css = 'body, .news-item-page, .news-page, .settings-page, .my-dealer-page, .my-dealer-page .header .column:last-child button, .cars-page, .road-help-page, .history-page, .service-request-page, .messages-page, .select-dealer-modal .dealer-item.active, .all-news-page, .history-page  { background-color: #181b1d; }' +
                ' .select-dealer-modal .dealer-item:hover, .new-chat-modal .content { background-color: #181b1d!important; }' +
                ' .new-chat-modal .content { border-radius: 0!important; }' +
                ' .white-paper { background-color: #000;}'+
                ' .content-panel input, .restore-panel input, .news-page .news-item, .all-news-page .news-items { background-color: #30363a; }' +
                ' .content-panel p, .content-panel h1, .restore-panel h1, .news-page .header h1, .news-page .news-item h2, .news-item-page .news-item, .titled-block .content, .settings-page .header h1, .titled-block .title, .my-dealer-page .header h1, .my-dealer-page .current-dealer-info-container .current-dealer-place-info-block .title, .my-dealer-page .current-dealer-info-container .current-dealer-place-info-block .address, .my-dealer-page .block-title, .my-dealer-page .empty-marketing, .my-dealer-page .current-dealer-info-container .contacts-container, .my-dealer-page .current-dealer-info-container .contacts-container a, .my-dealer-page .marketing-items .marketing-item, .cars-page .header h1, .cars-page .car-item .details-container .car-name, .cars-page .car-item .details-container .car-properties .column:last-child, .road-help-page .header h1, .road-help-page, .history-page .header h1, .year-selector .years-container .year-item, .service-request-page .header h1, .service-request-form .slot-selector .dates .date, .service-request-form .slot-selector .slots .slot, .service-request-form .slot-selector .select-slot-title, .service-request-form .slot-selector .year-select .select-date-description, .messages-page .header h1, .messages-page .chats-container .chat-item .type, .custom-checkbox-container, .car-selected-container .car-selected-info .details-container .car-properties .column:last-child, .register-panel .ui.form .field > label, .register-panel .go-back-message, .restore-panel .go-back-message, .register-panel h1, .select-dealer-modal .dealer-item, .select-dealer-modal .modal-title, .restore-panel .ui.form .field > label, .all-news-page .news-item .title, .all-news-page .header h1, .new-chat-modal .title, .car-selected-container .car-selected-info .details-container .car-name, .ui.modal p, .ui.modal .grid .row { color: #f5f5f5; }' +
                ' .menu-container, .custom-dropdown-container .ui.search.dropdown { color: #b2b2b2; }' +
                ' .news-page .header .column:last-child button.open-all-news-btn, .news-item-page .header .column:last-child button.open-all-news-btn, .messages-page .chats-container .chat-item.active, .all-news-page .header .column:last-child button.read-all { background-color: #595959;}' +
                ' .messages-page .chats-container .chat-item:hover { background-color: rgba(89,89,89,.8); }' +
                ' .news-page .news-item { border: 1px solid #30363a;}' +
                ' .cars-page .car-item .details-container .need-ssi-message { color: #FF7168; }' +
                ' .all-news-page .news-item { border-bottom: 1px solid #595959; }' +
                ' .news-item-page .news-item button { background-color: #30363a; border: 1px solid #c8c8c8; border-radius: 0; color: #c8c8c8; }' +
                ' .to-list-container .create-to-request-btn, .add_new_contact__btn.add_new_contact__agree { color: #181b1d; }' +
                ' .menu-items .menu-item.active { color: #30363a; }' +
                ' .year-selector .move-to-right { background: linear-gradient(90deg,#ffffff00 0 25%,#30363a 50% 100%); }' +
                ' .year-selector .move-to-left { background: linear-gradient(270deg,#ffffff00 0 26%,#30363a 40% 100%); }' +
                ' .quality-assessment-container .question-title, .question-scale-input .scale-item, .open-faq-btn { color: #fff; }' +
                ' .to-list tr.await { background: linear-gradient(90deg,#595959,rgba(89,89,89,0) 113.02%); }' +
                ' .to-list tr.complete { background: linear-gradient(90deg, #23B062 0%, rgba(35, 176, 98, 0) 131.32%); }' +
                ' .to-list tr.missing { background: linear-gradient(90deg, #E1121A 0%, rgba(225, 18, 26, 0) 113.02%); }' +
                ' .to-list { color: #dfdfdf; }' +
                ' .car-selected-container .need-go-to-service-container .title, .add_new_contact__btn.add_new_contact__reject { color: #FF7168; } ' +
                ' .question-scale-input .scale-item { border-color: #fff; }' +
                ' .quality-assessment-container .finish-btn, .register-panel .submit, .service-request-form .send-service-request, .messages-page .header .column:last-child button, .restore-panel .submit { background: linear-gradient(180deg,#e2b59e,#c38a6f); color: #181b1d; }' +
                ' .settings-page .save-btn, .settings-page .save-btn:disabled, .mark-element { background: linear-gradient(180deg,#e2b59e,#c38a6f); }' +
                ' .settings-page .save-btn:disabled { opacity: 0.5; cursor: default; }' +
                ' .select-dealer-input, .content-panel input, .restore-panel input, .select-dealer-modal .search-input-container input.search { background-color: #30363a; border: 1px solid #b2b2b2; color: #b2b2b2; }' +
                ' .custom-dropdown-container .ui.search.dropdown>input.search, .select-dealer-modal, .select-dealer-modal .content { background-color: #30363a!important; }' +
                ' .custom-input:placeholder-shown { color: #868686!important; }' +
                ' .custom-input { background-color: #30363a; border: 1px solid #b2b2b2; color: #f5f5f5; }' +
                ' .chat-container .chat-messages-container .message.in, .chat-container .dealer-chat-info { background-color: #30363a; color: #f5f5f5; }' +
                ' .chat-container .chat-messages-container .message.out { background-color: #595959; color: #f5f5f5; }' +
                ' .messages-page .chats-container .chat-item .sub-type { color: #3888ff; }' +
                ' .custom-input.with-value, .custom-textarea.with-value { background-color: #30363a; }' +
                ' .content-panel input, .restore-panel input, .restore-panel .ui.form input, .restore-panel .ui.form input:focus { background-color: #30363a; border: 1px solid #b2b2b2 !important; color: #b2b2b2; }' +
                ' .custom-textarea:placeholder-shown, .chat-container .chat-messages-container .message .date, .messages-page .chats-container .chat-item .department, .messages-page .chats-container .chat-item .messages-count { color: #b2b2b2!important; }' +
                ' .custom-textarea { background-color: #30363a; border: 1px solid #b2b2b2; color: #b2b2b2 !important }' +
                ' .custom-dropdown-container.with-value .ui.search.dropdown>input.search { background-color: #30363a!important; color: #b2b2b2; }' +
                ' .menu-container, .news-item-page .news-item, .titled-block .content, .my-dealer-page .current-dealer-info-container .current-dealer-place-info-block, .my-dealer-page .current-dealer-info-container .contacts-container, .my-dealer-page .marketing-items .marketing-item, .cars-page .car-item .details-container, .year-selector, .service-request-form, .messages-page .chats-container { background-color: #30363a; }' +
                ' .car-selected-container .car-selected-info .details-container, .quality-assessment-container, .to-list { background-color: #30363a; }' +
                ' .menu-container .first-name, .menu-container .last-name { color: '+window.config.color+'; }' +
                ' .register-panel .ui.form input, .register-panel .ui.form input:focus, .custom-file-input-container .label-name { background-color: #30363a; border: 1px solid #b2b2b2; color: #b2b2b2; }' +
                ' .download-book-btn { background: #181b1d; border: 1px solid #cf9b82; color: #cf9b82; }' +
                ' .register-panel .go-back-message a, .restore-panel .go-back-message a { background-color: #30363a; border: 1px solid '+window.config.color+'; color: '+window.config.color+'; }' +
                ' .loader, .loader::after, .loader::before { background: #d29f85; }' +
                ' .menu-container .unread-marker { background-color: #ff7168; }' +
                ' .new-chat-modal .grid .row .column.disabled:last-child, .new-chat-modal .grid .row .column:last-child { color: #23b062; }' +
                ' .ui.modal > .content { background-color: #181B1D; border-radius: 0!important; }' +
                ' .ui.modal > .content .title { color: #f5f5f5 }' +
                ' .logo-container img { width: 100%!important; }' +
                ' .ui.modal.delete-car-modal > .content > .title { color: #e1121a; }' +
                ' .remove-account-btn { background-color: #595959; }' +
                ' .menu-container .unread-marker { border: 2px solid #30363a; }' +
                ' .chat-container .create-message-block .input-for-message { background-color: #30363a; border: 1px solid #595959; }' +
                ' .custom-dropdown-container > .dropdown > .visible > .item { background-color: #30363a; color: #f5f5f5; }' +
                ' .custom-dropdown-container > .dropdown > .visible > .item:hover { background-color: #181b1d; color: #f5f5f5; }' +
                ' .custom-dropdown-container > .dropdown > .visible > .item.selected { background-color: #181b1d; color: #f5f5f5; }' +
                ' .success-create-serviceRequest-modal .important { color: #D29F85!important; }' +
                ' .service-request-page .service-request-item, .service-request-page .service-request-item .cancel-request-btn { background-color: #30363A; color: #f5f5f5 }' +
                ' .service-request-page .service-request-item .cancel-request-btn:hover { background-color: #181b1d; }' +
                ' .ui.modal.confirm-remove-serviceRequest-modal > .content > .title { color: #E1121A; }' +
                ' input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { transition: background-color 5500s ease-in-out 0s; -webkit-text-fill-color: #f5f5f5 !important; }' +
                ' h1 { font-family: Roboto!important; font-weight: 100; }' +
                ' .contacts__container, .add_new_contact__container, .add_new_contact__phone-input, .add_new_contact__btn.add_new_contact__reject { background-color: #30363a; }' +
                ' .contacts__container .grid .column .parameter_value, .contacts__title, .add_new_contact__grid_container .custom-title, .add_new_contact__phone-input { color: #f5f5f5; }' +
                ' .add_new_contact__btn.add_new_contact__reject { border-color: #FF7168; } '
            ;
            styleForDarkTheme.type = 'text/css';
            if (styleForDarkTheme.styleSheet){
                // This is required for IE8 and below.
                styleForDarkTheme.styleSheet.cssText = css;
            } else {
                styleForDarkTheme.appendChild(document.createTextNode(css));
            }
            head.appendChild(styleForDarkTheme);
        }

        if (window.config.mainDomain.includes('baic')) {
          let styleForBaicTheme = document.createElement('style');
          let css = ' h1 { font-family: Montserrat!important; font-weight: 500; }'
          styleForBaicTheme.type = 'text/css';

          if (styleForBaicTheme.styleSheet){
            // This is required for IE8 and below.
            styleForBaicTheme.styleSheet.cssText = css;
          } else {
            styleForBaicTheme.appendChild(document.createTextNode(css));
          }
          head.appendChild(styleForBaicTheme);
        }

        if (window.config.mainDomain.includes('kaiyi')) {
          let styleForKaiyiTheme = document.createElement('style');
          let css = ' h1 { font-family: Roboto!important; font-weight: 800; }'
          styleForKaiyiTheme.type = 'text/css';

          if (styleForKaiyiTheme.styleSheet){
            // This is required for IE8 and below.
            styleForKaiyiTheme.styleSheet.cssText = css;
          } else {
            styleForKaiyiTheme.appendChild(document.createTextNode(css));
          }
          head.appendChild(styleForKaiyiTheme);
        }

        if(window.location.search.indexOf('?marker=') === 0){
            let marker = window.location.search.replace('?marker=', '');
            localStorage.clear();
            api()
                .loginByMarker(marker)
                .then(resp=>{
                    localStorage.setItem('AccessToken', resp.AccessToken);
                    api()
                        .getUserInfo()
                        .then((resp)=>{
                            localStorage.setItem('UserId', resp.UserId);
                            window.location = window.location.pathname === '/' ? '/news' : window.location.pathname;
                        });
                });
        } else {
            if(window.location.search.indexOf('?token=') === 0){
                let token = window.location.search.replace('?token=', '');
                localStorage.clear();
                localStorage.setItem('AccessToken', token);
                api()
                    .getUserInfo()
                    .then((resp)=>{
                        localStorage.setItem('UserId', resp.ClientId);
                        window.location = window.location.pathname === '/' ? '/news' : window.location.pathname;
                    });
            }else{
                const root = ReactDOM.createRoot(document.getElementById('root'));
                root.render(<App />);
                reportWebVitals();
            }
        }
    });
